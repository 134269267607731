'use client';

import React, { useEffect } from 'react';
import { notFound, usePathname, useRouter } from 'next/navigation';

import { useQuest } from '@zealy/queries';

import Skeletons from '#components/Skeletons';

import ClaimQuest from './_components/ClaimQuest';

export const ClaimQuestPage = ({
  questId,
  subdomain,
  moduleId,
}: {
  questId: string;
  subdomain: string;
  moduleId: string;
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const quest = useQuest(questId, subdomain, moduleId);

  useEffect(() => {
    if (quest?.data?.categoryId && quest.data.categoryId !== moduleId) {
      router.replace(pathname.replace(moduleId, quest.data.categoryId));
    }
  }, [moduleId, pathname, quest.data, router]);

  if (quest.isLoading || !quest.data) return <Skeletons.QuestPage />;

  if (!quest.data) return notFound();

  return <ClaimQuest quest={quest.data} />;
};
